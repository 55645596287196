import React, { useEffect } from 'react';
import './User.css'
import useVH from 'react-viewport-height';
import background from '../assets/user_background.png'
import profile from '../assets/user_new.png'
import game1 from '../assets/wildjumps.jpg'
import game2 from '../assets/favourite_2.png'
import game3 from '../assets/monstertruck.jpg'
import game4 from '../assets/Underwater.jpg'
import userdfav1 from '../assets/user_d_fav_1.png'
import userdfav2 from '../assets/user_d_fav_2.png'
import comingSoon from '../assets/coming_soon.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MediaQuery from 'react-responsive'
import Header from '../components/Header'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import BottomBar from '../components/BottomBar';
import { useNavigate, Link } from 'react-router-dom';
import { AiFillSetting } from "react-icons/ai";
import {LazyLoadImage} from "react-lazy-load-image-component"

function User() {

    const random = Math.floor(1000 + Math.random() * 90000);
    const random1 = Math.floor(1000 + Math.random() * 9000);
    const random2 = Math.floor(1000 + Math.random() * 9000);
    const random3 = Math.floor(1000 + Math.random() * 9000);
    const random4 = Math.floor(1000 + Math.random() * 9000);
    const random5 = Math.floor(1000 + Math.random() * 9000);
    const random6 = Math.floor(1000 + Math.random() * 9000);

    let navigate = useNavigate()

    const uid = localStorage.getItem("uid");

    // useEffect(() => {
    //     if(uid) {
    //     } else {
    //         navigate('/signin')
    //     }
    // })

    // const logOut = () => {
    //     localStorage.removeItem("uid")
    //     navigate('/')
    // }

    // eslint-disable-next-line
    const vh = useVH();
    return (
        <div>
            <MediaQuery maxWidth={767}>
                <div className='userDiv'>
                    <img src={background} alt="background_user" className='user_background'></img>
                    <div className='user_info'>
                        <div style={{position: 'relative', width: '100px', margin: 'auto'}}>
                            <img src={profile} alt="user_profile" className='user_profile'></img>
                            <div className='user_profile_online'></div>
                        </div>
                        <h3>Kaliguro</h3>
                        <h6>@kaliboyz</h6>
                        <p>Only the brave survive. Join to on my journey to Valhalla.</p>
                    </div>
                    <div className='user_friends'>
                        <div style={{position: 'relative'}}>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?gamer' className='user_friend' />
                            <div className='user_friend_profile_online'></div>
                            <h6>@chapulinzen</h6>
                        </div>
                        <div style={{position: 'relative'}}>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?gamergirl' className='user_friend' />
                            <div className='user_friend_profile_online'></div>
                            <h6>@masterhaseo</h6>
                        </div>
                        <div style={{position: 'relative'}}>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?profile' className='user_friend' />
                            <div className='user_friend_profile_online'></div>
                            <h6>@larvuz</h6>
                        </div>
                        <div style={{position: 'relative'}}>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?tech' className='user_friend' />
                            <div className='user_friend_profile_offline'></div>
                            <h6>@buso</h6>
                        </div>
                        <div style={{position: 'relative'}}>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?girl' className='user_friend' />
                            <div className='user_friend_profile_offline'></div>
                            <h6>@digidois</h6>
                        </div>
                        <div style={{position: 'relative'}}>
                            <img src="https://source.unsplash.com/random/100x100/" alt="user_friend" className='user_friend'></img>
                            <div className='user_friend_profile_offline'></div>
                            <h6>@danielsan</h6>
                        </div>
                    </div>
                    <div className='user_favourites'>
                            <Link to={'/game'}>
                                <div className='user_favourites_item'>
                                    <img src={game2} alt="favourite game" className='user_favourites_img'></img>
                                    <div className='user_favourites_text'>
                                        <div className='user_favourites_text_tabs'>
                                            <small>RPG</small>
                                            <small>Adventure</small>
                                            <small>Realistic</small>
                                        </div>
                                        <h4>Souls of Nature</h4>
                                        <h6>@metazooiestudios</h6>
                                    </div>
                                </div>
                            </Link>
                            
                    <Link to="/wildjumps">
                        <div className='user_favourites_item'>
                            <img src={game1} alt="favourite game" className='user_favourites_img'></img>
                            <div className='user_favourites_text'>
                                <div className='user_favourites_text_tabs'>
                                    <small>Platform</small>
                                    <small>Action</small>
                                </div>
                                <h4>Wild Jumps</h4>
                                <h6>@metazooiestudios</h6>
                            </div>
                        </div>
                    </Link>
                    <Link to="/truckmania">
                        <div className='user_favourites_item'>
                            <img src={game3} alt="favourite game" className='user_favourites_img'></img>
                            <div className='user_favourites_text'>
                                <div className='user_favourites_text_tabs'>
                                    <small>Action</small>
                                    <small>Cars</small>
                                </div>
                                <h4>Truck Mania</h4>
                                <h6>@metazooiestudios</h6>
                            </div>
                        </div>
                    </Link>
                    <Link to="/underwater">
                        <div className='user_favourites_item'>
                            <img src={game4} alt="favourite game" className='user_favourites_img'></img>
                            <div className='user_favourites_text'>
                                <div className='user_favourites_text_tabs'>
                                    <small>Explore</small>
                                    <small>Adventure</small>
                                </div>
                                <h4>Underwater World</h4>
                                <h6>@metazooiestudios</h6>
                            </div>
                        </div>
                    </Link>
                    </div>
                    <BottomBar />
                </div>
            </MediaQuery>
            <MediaQuery minWidth={768}>
                <div className='user_D_Div'>
                    <Header />
                    <div className='user_D_background user_D_ProfileDiv'>
                        <img src={profile} alt="user_profile" className='user_D_profile'></img>
                        <h2>Kaliguro</h2>
                        <small>@kaliboyz</small>
                        <p>Only the brave survive. Join to on my journey to Valhalla.</p>
                    </div>
                    <div className='user_D_background user_D_FriendsDiv'>
                        <h2>Online Friends</h2>
                        <div className='user_D_FriendsDiv_Row'>
                            <div className='user_D_FriendsDiv_Row_Div'>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?gamer' className='user_profile' />
                                <small>@chapulinzen</small>
                            </div>
                            <div className='user_D_FriendsDiv_Row_Div'>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?gamergirl' className='user_profile' />
                                <small>@masterhaseo</small>
                            </div>
                            <div className='user_D_FriendsDiv_Row_Div'>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?tech' className='user_profile' />
                                <small>@larvuz</small>
                            </div>
                        </div>
                        <div className='user_D_FriendsDiv_Row'>
                            <div className='user_D_FriendsDiv_Row_Div'>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?profile' className='user_profile' />
                                <small>@buso</small>
                            </div>
                            <div className='user_D_FriendsDiv_Row_Div'>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?men' className='user_profile' />
                                <small>@digidois</small>
                            </div>
                            <div className='user_D_FriendsDiv_Row_Div'>
                            <LazyLoadImage alt="user_friend" effect='blur' src='https://source.unsplash.com/random/100x100/?gamer52' className='user_profile' />
                                <small>@danielsan</small>
                            </div>
                        </div>
                    </div>
                    <div className='user_D_background user_D_DashDiv'>
                        <h2>Favourite games</h2>
                        <div className='user_D_DashDiv_Carousel'>
                            <Splide options={ {
                                perPage: 2,
                                padding: {right: 100},
                                arrows: false,
                                pagination: false
                            }} aria-label="My Favorite Images">
                                <SplideSlide>
                                    <Link to='/game'>
                                        <div className='user_D_DashDiv_SplideDiv' style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.8) 100%), url(${game2})`}}>
                                            <div className='user_D_DashDiv_SplideDiv_Tags'>
                                                <p className='user_D_DashDiv_SplideDiv_Tag'>RPG</p>
                                                <p className='user_D_DashDiv_SplideDiv_Tag'>Adventure</p>
                                                <p className='user_D_DashDiv_SplideDiv_Tag'>Realistic</p>
                                            </div>
                                            <h3>Souls of Nature</h3>
                                            <h4>@metazooiestudios</h4>
                                        </div>
                                    </Link>
                                </SplideSlide>
                                <SplideSlide>
                                <Link to='/wildjumps'>
                                    <div className='user_D_DashDiv_SplideDiv' style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.8) 100%), url(${game1})`}}>
                                        <div className='user_D_DashDiv_SplideDiv_Tags'>
                                            <p className='user_D_DashDiv_SplideDiv_Tag'>Platform</p>
                                            <p className='user_D_DashDiv_SplideDiv_Tag'>Action</p>
                                        </div>
                                        <h3>Wild Jumps</h3>
                                        <h4>@metazooiestudios</h4>
                                    </div>
                                    </Link>
                                </SplideSlide>
                                <SplideSlide>
                                <Link to='/truckmania'>
                                    <div className='user_D_DashDiv_SplideDiv' style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.8) 100%), url(${game3})`}}>
                                        <div className='user_D_DashDiv_SplideDiv_Tags'>
                                            <p className='user_D_DashDiv_SplideDiv_Tag'>Action</p>
                                            <p className='user_D_DashDiv_SplideDiv_Tag'>Cars</p>
                                        </div>
                                        <h3>Truck Mania</h3>
                                        <h4>@metazooiestudios</h4>
                                    </div>
                                    </Link>
                                </SplideSlide>
                            </Splide>
                        </div>
                        <div className='user_D_DashDiv_Activity'>
                            <h2>Recent Activity</h2>
                            <div className='user_D_DashDiv_ActivityRow'>
                                <div className='user_D_DashDiv_ActivityRow_Div'>
                                    <img src='https://source.unsplash.com/random/100x100/?gamergirl' alt="Recent Activity"></img>
                                    <div className='user_D_DashDiv_ActivityRow_Div_Right'>
                                        <h3>@masterhaseo</h3>
                                        <p>Achieved the 5th place on the leaderboard!</p>
                                    </div>
                                    <b>4 min ago</b>
                                </div>
                                <div className='user_D_DashDiv_ActivityRow_Div'>
                                    <img src='https://source.unsplash.com/random/100x100/?tech' alt="Recent Activity"></img>
                                    <div className='user_D_DashDiv_ActivityRow_Div_Right'>
                                        <h3>@larvuz</h3>
                                        <p>Started playing Souls of Nature!</p>
                                    </div>
                                    <b>12 min ago</b>
                                </div>
                            </div>
                            <div className='user_D_DashDiv_ActivityRow'>
                                <div className='user_D_DashDiv_ActivityRow_Div'>
                                    <img src='https://source.unsplash.com/random/100x100/?gamer' alt="Recent Activity"></img>
                                    <div className='user_D_DashDiv_ActivityRow_Div_Right'>
                                        <h3>@chapulinzen</h3>
                                        <p>Started playing Wild Jump!</p>
                                    </div>
                                    <b>16 min ago</b>
                                </div>
                                <div className='user_D_DashDiv_ActivityRow_Div'>
                                    <img src='https://source.unsplash.com/random/100x100/?gamer52' alt="Recent Activity"></img>
                                    <div className='user_D_DashDiv_ActivityRow_Div_Right'>
                                        <h3>@danielsan</h3>
                                        <p>Achieved the 1th place on the leaderboard!</p>
                                    </div>
                                    <b>30 min ago</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default User;