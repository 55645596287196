import React from 'react';
import MediaQuery from 'react-responsive'
import './Home.css';
import BottomBar from '../components/BottomBar';
import GameIndex from '../components/GameIndex';
import GameIndex2 from '../components/GameIndex2';
import GameIndex3 from '../components/GameIndex3';
import GameIndex4 from '../components/GameIndex4';
import HomeDesktop from './HomeDesktop';

const Home = () => {
  return (
    <div className='root'>
      <MediaQuery minWidth={768}>
        <HomeDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <GameIndex />
        <GameIndex2 />
        <GameIndex3 />
        <GameIndex4 />
        <BottomBar /> 
      </MediaQuery>
    </div>
  )
}

export default Home