import React, { useState, useEffect } from 'react';
import './Game.css'
import useVH from 'react-viewport-height';
import background from '../assets/wildjumps.jpg'
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import metazooie from '../assets/metazooie.png'
import studio from '../assets/metazooie.png'
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive'
import Header from '../components/Header';
import BottomBar from '../components/BottomBar';
import ScrollToTop from '../components/ScrollToTop';
function Game2() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    const irJuego = () => {
        const botonprueba = document.getElementById("pruebaboton2");
        botonprueba.click();
        const backgrounddiv = document.getElementById("div_background");
        backgrounddiv.classList.toggle('backgrounddiv_disable');
        setTimeout(() => {
          const uid = document.getElementById("root");
          uid.classList.toggle('gameroot_active');
        }, 500);
    }

    const random1 = Math.floor(1000 + Math.random() * 9000);
    const random2 = Math.floor(1000 + Math.random() * 9000);
    const random_users = Math.floor(10 + Math.random() * 99);
    
    const random_lead_1 = Math.floor(1000 + Math.random() * 9000);
    const random_lead_2 = Math.floor(1000 + Math.random() * 9000);
    const random_lead_3 = Math.floor(1000 + Math.random() * 9000);
    const random_lead_4 = Math.floor(1000 + Math.random() * 9000);

    // eslint-disable-next-line
    const vh = useVH();

    const [isActive, setActive] = useState(false);

    const favorito = () => {
        setActive(!isActive);
    }
    return (
        <div>
            <MediaQuery maxWidth={767}>
                <div className='gameDiv'>
                    <div className='gameBack'>
                        <img src={background} alt="background_game" className='game_background'></img>
                        <div className='gameback_overlay'></div>
                        <div className='gameBack_inner'>
                            <div className='tags'>
                                <small className='tag'>Platform</small>
                                <small className='tag'>Action</small>
                            </div>
                            <h2>Wild Jumps</h2>
                            <p>Help the brave doggy jump across platforms and beat the clock in this fast-paced, exciting game.</p>
                            {isActive == true ? <AiFillStar onClick={favorito} className="svg svgactive" color='#FFB800' size={26} /> : <AiOutlineStar onClick={favorito} className="svg" color='white' size={26} />}
                            <div className="studio_div">
                                <Link to="/studio">
                                    <div className='studio'>
                                    <img src={metazooie} alt="logo"></img>
                                    <span>@metazooiestudios</span>
                                    </div>
                                </Link>
                            </div>
                            <button onClick={irJuego} className='game_playbtn'>Play</button>
                        </div>
                    </div>
                    <div className='game_bottom'>
                        <div className='game_stats'>
                            <div className='game_stat'>
                                <b>1,000</b>
                                <h4>Followers</h4>
                            </div>
                            <div className='game_stat'>
                                <b>5th</b>
                                <h4>Rank</h4>
                            </div>
                            <div className='game_stat'>
                                <b>150</b>
                                <h4>Hrs. Played</h4>
                            </div>
                        </div>
                        <div className='game_social'>
                            <div className='game_socialfriends'>
                                <img src="https://source.unsplash.com/random/100x100/?men" alt="friend"></img>
                                <img src="https://source.unsplash.com/random/100x100/?women" alt="friend"></img>
                                <img src="https://source.unsplash.com/random/100x100/?gamer" alt="friend"></img>
                            </div>
                            <p><b>@larvuz, @buso</b> & 9 other friends played this game</p>
                        </div>
                    </div>
                    <div className='game_leaderboard'>
                        <h2>Leaderboard</h2>
                        <div className='game_leaderboard_first'>
                            <img src="https://source.unsplash.com/random/100x100/?gaming" alt="first place" className='game_leaderboard_first_img'></img>
                            <p className='game_leaderboard_first_usertag'>@nicsmercks</p>
                            <p className='game_leaderboard_first_score'>8122</p>
                        </div>
                        <div className='game_leaderboard_div'>
                            <small>2</small>
                            <div className='game_leaderboard_div_inner'>
                                <img src="https://source.unsplash.com/random/100x100/?profile" alt="first place" className='game_leaderboard_div_inner_user'></img>
                                <small>@chapulinzen</small>
                                <p className='game_leaderboard_div_inner_score'>8043</p>
                            </div>
                        </div>
                        <div className='game_leaderboard_div'>
                            <small>3</small>
                            <div className='game_leaderboard_div_inner'>
                                <img src="https://source.unsplash.com/random/100x100/?womengamer" alt="first place" className='game_leaderboard_div_inner_user'></img>
                                <small>@masterhaseo</small>
                                <p className='game_leaderboard_div_inner_score'>7914</p>
                            </div>
                        </div>
                        <div className='game_leaderboard_div'>
                            <small>4</small>
                            <div className='game_leaderboard_div_inner'>
                                <img src="https://source.unsplash.com/random/100x100/?tech" alt="first place" className='game_leaderboard_div_inner_user'></img>
                                <small>@digidois</small>
                                <p className='game_leaderboard_div_inner_score'>7420</p>
                            </div>
                        </div>
                        <div className='game_leaderboard_div'>
                            <small>5</small>
                            <div className='game_leaderboard_div_inner'>
                                <img src="https://source.unsplash.com/random/100x100/?game" alt="first place" className='game_leaderboard_div_inner_user'></img>
                                <small>@mongraal</small>
                                <p className='game_leaderboard_div_inner_score'>7281</p>
                            </div>
                        </div>
                    </div>
                    <BottomBar />
                </div>
            </MediaQuery>
            <MediaQuery minWidth={768}>
                <div className='game_D_Div'>
                    <Header />
                    <div className='game_D_Hero' style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 13.42%, #000000 100%), url('../images/wildjumps.jpg')`}}>
                        <Link to="/studio" style={{ textDecoration: 'none', color: 'white' }}>
                            <div className='Home_Studio'>
                                <img src={studio} alt="Studio profile"></img>
                                <p>@metazooiestudios</p>
                            </div>
                        </Link>
                        <h2>WILD JUMPS</h2>
                        <p>Help the brave doggy jump across platforms and beat the clock in this fast-paced, exciting game.</p>
                        <div className='Home_Tags'>
                            <p>RPG</p>
                            <p>Adventure</p>
                            <p>Realistic</p>
                        </div>
                    </div>
                    <div className='game_D_Bottom'>
                        <div className='game_D_Stats'>
                            <div className='game_D_Stat'>
                                <b>1,000</b>
                                <h4>Followers</h4>
                            </div>
                            <div className='game_D_Stat'>
                                <b>5th</b>
                                <h4>Rank</h4>
                            </div>
                            <div className='game_D_Stat'>
                                <b>150</b>
                                <h4>Hrs. Played</h4>
                            </div>
                            {isActive == true ? <AiFillStar onClick={favorito} className="svg svgactive" color='#FFB800' size={36} /> : <AiOutlineStar onClick={favorito} className="svg" color='white' size={36} />}
                        </div>
                        <div className='game_D_social'>
                            <div className='game_D_Friends'>
                                <img src="https://source.unsplash.com/random/100x100/?men" alt="friend"></img>
                                <img src="https://source.unsplash.com/random/100x100/?women" alt="friend"></img>
                                <img src="https://source.unsplash.com/random/100x100/?gamer" alt="friend"></img>
                            </div>
                            <p><b>@larvus, @buso</b> & 8 other<br></br>friends played this game</p>
                        </div>
                    </div>
                    <div className='game_D_Inside'>
                        <div className='game_D_Inside_Leaderboard'>
                            <h3>Leaderboard</h3>
                            <div className='game_D_Inside_Leaderboard_Table'>
                                <div className='game_D_Leaderboard_Div game_D_Leaderboard_First'>
                                    <p>1st</p>
                                    <img src="https://source.unsplash.com/random/100x100/?gaming" alt="friend"></img>
                                    <h5>@nickmercks</h5>
                                    <h6>8122</h6>
                                </div>
                                <div className='game_D_Leaderboard_Div game_D_Leaderboard_Second'>
                                    <p>2nd</p>
                                    <img src="https://source.unsplash.com/random/100x100/?profile" alt="friend"></img>
                                    <h5>@chapulinzen</h5>
                                    <h6>8122</h6>
                                </div>
                                <div className='game_D_Leaderboard_Div game_D_Leaderboard_Third'>
                                    <p>3th</p>
                                    <img src="https://source.unsplash.com/random/100x100/?womengamer" alt="friend"></img>
                                    <h5>@masterhaseo</h5>
                                    <h6>8122</h6>
                                </div>
                                <div className='game_D_Leaderboard_Div'>
                                    <p>4</p>
                                    <img src="https://source.unsplash.com/random/100x100/?tech" alt="friend"></img>
                                    <h5>@digidois</h5>
                                    <h6>8122</h6>
                                </div>
                                <div className='game_D_Leaderboard_Div'>
                                    <p>5</p>
                                    <img src="https://source.unsplash.com/random/100x100/?game" alt="friend"></img>
                                    <h5>@mongraal</h5>
                                    <h6>8122</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <ScrollToTop />
        </div>
    );
}

export default Game2;