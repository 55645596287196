import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import * as serviceWorker from './serviceWorker';

import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Game from './pages/Game';
import AppWrapper from './App';
import User from './pages/User';
import Studio from './pages/Studio';
import SignIn from './pages/SignIn';
import Game2 from './pages/Game2';
import Game3 from './pages/Game3';
import Game4 from './pages/Game4';

ReactDOM.render(<AppWrapper />, document.getElementById('gameroot'));

ReactDOM.render(
    <Router>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route exact path='/game' element={<Game />} />
            <Route exact path='/wildjumps' element={<Game2 />} />
            <Route exact path='/truckmania' element={<Game3 />} />
            <Route exact path='/underwater' element={<Game4 />} />
            <Route exact path='/user' element={<User />} />
            <Route exact path='/studio' element={<Studio />} />
            <Route exact path='/signin' element={<SignIn />} />
        </Routes>
    </Router>,
    document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
