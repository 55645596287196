import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import useVH from 'react-viewport-height'
import ob_1 from './assets/onboard_1.png'
import ob_2 from './assets/onboard_2.png'
import ob_3 from './assets/onboard_3.png'
import ob_4 from './assets/onboard_4.png'
import './pages/OnBoard.css'
import { AiFillCaretRight } from "react-icons/ai";

import './Launch.css';
import clientConfig from './client.json';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'description';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  // useEffect(() => {
  //   const intervalID = setTimeout(() =>  {
  //     props.Launch();
  //   }, 2000);
  // }, []);

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
  )
}

  const [isActive1, setActive1] = React.useState<boolean>(true);

    const handleToggle1 =  () => {
      sleep(200).then(()=>{
        setActive1(!isActive1);
        setActive2(!isActive2);
      })
    };
    
    const [isActive2, setActive2] = React.useState<boolean>(false);

    const handleToggle2 = () => {
        sleep(200).then(()=>{
          setActive2(!isActive2);
          setActive3(!isActive3);
        })
    };
    
    const [isActive3, setActive3] = React.useState<boolean>(false);

    const handleToggle3 = () => {
      sleep(200).then(()=>{
        setActive3(!isActive3);
        setActive4(!isActive4);
      })
    };
    
    const [isActive4, setActive4] = React.useState<boolean>(false);

    const handleToggle4 = async () => {
      const uid = document.getElementById("gameroot");
      uid.style.zIndex = "1";
      setActive4(!isActive4);
      sleep(200).then(()=>{
        props.Launch()
      })
      // alert(isActive1)
    };

    useEffect(() => {
      
    })

    // eslint-disable-next-line
    const vh = useVH();
  return (
    <div id="launchContainer" style={{ position: 'absolute', zIndex: '999999', top: '0', left: '0' }}>
      <div className='onboard_div'>
            <div className={isActive1 == true ? 'onboard_inner ob_active' : 'onboard_inner'} id='ob_one'>
                <img src={ob_1} alt="OnBoard" className='ob_image'></img>
                <div className='onboard_bottom'>
                    <h2>Swipe down to browse games</h2>
                    <p>Find new releases, classics and your favorite titles.</p>
                    <button onClick={handleToggle1} className='onboard_button'>
                        Next
                    </button>
                </div>
            </div>
            <div className={isActive2 == true  ? 'onboard_inner ob_active' : 'onboard_inner'} id='ob_two'>
                <img src={ob_2} alt="OnBoard" className='ob_image'></img>
                <div className='onboard_bottom'>
                    <h2>Swipe right to play</h2>
                    <p>Click on names to open the game’s profile and the game studio’s details.</p>
                    <button onClick={handleToggle2} className='onboard_button'>
                        Next
                    </button>
                    <br />
                    <button onClick={handleToggle1} className='onboard_button_back'>
                        Back
                    </button>
                </div>
            </div>
            <div className={isActive3 == true  ? 'onboard_inner ob_active' : 'onboard_inner'} id='ob_three'>
                <img src={ob_3} alt="OnBoard" className='ob_image'></img>
                <div className='onboard_bottom'>
                    <h2>Learn about games & creators</h2>
                    <p>Click on names to open the game’s profile and the game studio’s details.</p>
                    <button onClick={handleToggle3} className='onboard_button'>
                        Next
                    </button>
                    <br />
                    <button onClick={handleToggle2} className='onboard_button_back'>
                        Back
                    </button>
                </div>
            </div>
            <div className={isActive4 == true  ? 'onboard_inner ob_active' : 'onboard_inner'} id='ob_four'>
                <img src={ob_4} alt="OnBoard" className='ob_image'></img>
                <div className='onboard_bottom'>
                    <h2>Like & follow your favs</h2>
                    <p>Save your favorites in one place with one click.</p>
                    <button onClick={() => {
                      handleToggle4()
                    }} className='onboard_button'>
                    {/* <button onClick={handleToggle4} className='onboard_button'> */}
                    {/* <button onClick={() => props.Launch()} className='onboard_button'> */}
                        Start
                    </button>
                    <br />
                    <button onClick={handleToggle3} className='onboard_button_back'>
                        Back
                    </button>
                </div>
            </div>
        </div>
      {/* <div>
        <Button size="massive" color="blue" circular icon="play" onClick={() => props.Launch()}></Button>
      </div> */}
    </div>
  );
};
